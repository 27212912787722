import { CoverageAreasDTO } from './DeliveryCenter.DTO'

export function toCoverageAreasDTO (data: any[]): CoverageAreasDTO[] {
  return data.map(
    (item: any) =>
      ({
        externalId: item.externalId,
        city: item.city,
        state: item.state
      }) as CoverageAreasDTO
  )
}
