import { IRepositoryRequirements } from 'repositories/repositories.common'
import { CreateChargesProps } from './Charges.props'
import { Fetch } from 'utils'

export function ChargesRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/charges`

  async function create ({ payload, type }: CreateChargesProps) {
    const urlEndpointByType = {
      DELIVERY_FEE: `${basePath}/delivery-fee`,
      PAYMENT_FEE: `${basePath}/payment-fee`,
      ITEM_FEE: `${basePath}/item-fee`,
      GENERAL_CHARGES: `${basePath}/general-charges`
    }
    const url = `${urlEndpointByType[type]}`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  return {
    create
  }
}
