import { Fetch } from 'utils'
import { IRepositoryRequirements } from '../../repositories.common'
import { RecommendationsCreateProps, RecommendationsCreatePropsDDC, ShopByBrandCreateProps } from './Recommendations.props'

export function RecommendationsRepository ({ authentication, baseUrl }: IRepositoryRequirements) {
  const url = `${baseUrl}/recommendations`

  function create (data: RecommendationsCreateProps) {
    return Fetch.post({ url, data, config: { headers: authentication } })
  }

  async function createDDC (data: RecommendationsCreatePropsDDC) {
    return Fetch.post({ url: `${url}/ddc`, data, config: { headers: authentication } })
  }

  const createShopByBrand = async (data: ShopByBrandCreateProps) => {
    return Fetch.post({
      url: `${url}/shop-by-brand`,
      data,
      config: { headers: authentication }
    })
  }

  return {
    create,
    createDDC,
    createShopByBrand
  }
}
