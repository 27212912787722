import { IRepositoryRequirements } from 'repositories/repositories.common'
import {
  ICreateDealCrossDiscount,
  ICreateDealDiscount,
  ICreateDealDiscountCoupon,
  ICreateDealFreeGood,
  ICreateDealFreeGoodMinimumAmount,
  ICreateDealInteractiveCombo,
  ICreateDealInteractiveComboMinimumAmount,
  ICreateDealOrderTotalSteppedDiscount,
  ICreateDealPriceReduction,
  ICreateDealSpecialOffers,
  ICreateDealSteppedDiscount,
  ICreateDealSteppedFreeGood,
  ICreateDealSteppedFreeGoodByAmount,
  ICreateDealSteppedInteractiveComboByAmount
} from './Deals.props'
import { Fetch } from 'utils'

export function DealsRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/deals`

  async function createDealDiscount (payload: ICreateDealDiscount) {
    const url = `${basePath}/discount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedDiscount (payload: ICreateDealSteppedDiscount) {
    const url = `${basePath}/stepped-discount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealOrderTotalSteppedDiscount (payload: ICreateDealOrderTotalSteppedDiscount) {
    const url = `${basePath}/order-total-stepped-discount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealDiscountCoupon (payload: ICreateDealDiscountCoupon) {
    const url = `${basePath}/discount-coupon`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealFreeGood (payload: ICreateDealFreeGood) {
    const url = `${basePath}/free-good`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealFreeGoodMinimumAmount (payload: ICreateDealFreeGoodMinimumAmount) {
    const url = `${basePath}/free-good-minimum-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedFreeGood (payload: ICreateDealSteppedFreeGood) {
    const url = `${basePath}/stepped-free-good`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedFreeGoodByAmount (payload: ICreateDealSteppedFreeGoodByAmount) {
    const url = `${basePath}/stepped-free-good-by-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveCombo (payload: ICreateDealInteractiveCombo) {
    const url = `${basePath}/interactive-combo`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboMinimumAmount (payload: ICreateDealInteractiveComboMinimumAmount) {
    const url = `${basePath}/interactive-combo-minimum-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealInteractiveComboWithVariants (payload: ICreateDealInteractiveCombo) {
    const url = `${basePath}/interactive-combo-with-variants`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSteppedInteractiveComboByAmount (payload: ICreateDealSteppedInteractiveComboByAmount) {
    const url = `${basePath}/stepped-interactive-combo-by-amount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealPriceReduction (payload: ICreateDealPriceReduction) {
    const url = `${basePath}/price-reduction`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealCrossDiscount (payload: ICreateDealCrossDiscount) {
    const url = `${basePath}/cross-discount`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  async function createDealSpecialOffers (payload: ICreateDealSpecialOffers) {
    const url = `${basePath}/special-offers`

    return Fetch.post({
      url,
      data: { ...payload },
      config: { headers: { ...authentication } }
    })
  }

  return {
    createDealDiscount,
    createDealSteppedDiscount,
    createDealOrderTotalSteppedDiscount,
    createDealDiscountCoupon,
    createDealFreeGood,
    createDealFreeGoodMinimumAmount,
    createDealSteppedFreeGood,
    createDealSteppedFreeGoodByAmount,
    createDealInteractiveCombo,
    createDealInteractiveComboWithVariants,
    createDealInteractiveComboMinimumAmount,
    createDealSteppedInteractiveComboByAmount,
    createDealPriceReduction,
    createDealCrossDiscount,
    createDealSpecialOffers
  }
}
