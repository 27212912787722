import { AccountReadDTO } from './Account.dto'

export const mapAccountRead = (data: any): AccountReadDTO => ({
  account: {
    id: data.vendorAccountId,
    accountId: data.accountId,
    taxId: data.taxId,
    name: data.name,
    status: data.status,
    paymentMethods: data.paymentMethods,
    customerAccountId: data.customerAccountId
  },
  credit: data.credit && {
    available: data.credit?.available || undefined,
    balance: data.credit?.balance || undefined,
    consumption: data.credit?.consumption || undefined,
    overdue: data.credit?.overdue || undefined
  },
  deliveryWindow: data.deliveryWindows && {
    startDate: new Date(data.deliveryWindows[0]?.startDate),
    endDate: new Date(data.deliveryWindows[0]?.endDate),
    expirationDate: new Date(data.deliveryWindows[0]?.expirationDate),
    alternativeDeliveryDate: data.deliveryWindows[0]?.alternativeDeliveryDate
  },
  elegibleToRewards: data.rewardsProgram,
  isKeyAccount: data.isKeyAccount,
  pickupAllowed: data.pickupAllowed,
  pickupOrder: data.pickupOrder,
  hasPONumberRequirement: data.hasPONumberRequirement || undefined,
  minMax: {
    minType: data.minimumOrder?.type,
    minValue: data.minimumOrder?.value,
    maxType: data.maximumOrder?.type,
    maxValue: data.maximumOrder?.value
  },
  liquorLicense: {
    number: data.liquorLicense?.number,
    expirationDate: data.liquorLicense?.expirationDate ? new Date(data.liquorLicense.expirationDate) : undefined,
    status: data.liquorLicense?.status,
    type: data.liquorLicense?.type
  },
  paymentTerms: data.paymentTerms,
  owner: {
    email: data.owner?.email,
    firstName: data.owner?.firstName,
    lastName: data.owner?.lastName,
    phone: data.owner?.phone
  },
  zipcode: data.zipcode
})
