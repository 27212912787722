/* eslint-disable no-unused-vars */
import { lazy, LazyExoticComponent, ReactElement } from 'react'
import {
  User,
  Tag,
  Currency,
  RecycleBottle,
  Package,
  DocumentSigned,
  Megaphone,
  Gift,
  HelpCircle,
  MapPin,
  Search
} from '@hexa-ui/icons'
import { HubProps } from 'layouts'

type Modules =
  | 'ACCOUNT'
  | 'PRODUCTS'
  | 'ORDERS'
  | 'EMPTIES'
  | 'INVOICE'
  | 'RECOMMENDATIONS'
  | 'REWARDS'
  | 'SUPPORT'
  | 'DDC'
  | 'PROMOTIONS'
  | 'SEARCH'

type RouterProps = { [Module in Modules]: { path: string; Element: LazyExoticComponent<() => ReactElement> } }
type SidebarProps = { [Module in Modules]: HubProps['sidebar'][0] }

export const basename = 'data-generator'

export const Routes: RouterProps = {
  ACCOUNT: { path: '/accounts', Element: lazy(() => import('views/Account')) },
  PRODUCTS: { path: '/products', Element: lazy(() => import('views/Product')) },
  PROMOTIONS: { path: '/promotions', Element: lazy(() => import('views/Promotions')) },
  ORDERS: { path: '/orders', Element: lazy(() => import('views/Orders')) },
  EMPTIES: { path: '/empties', Element: lazy(() => import('views/Empties')) },
  INVOICE: { path: '/invoices', Element: lazy(() => import('views/Invoice')) },
  RECOMMENDATIONS: { path: '/recommendations', Element: lazy(() => import('views/Recommendations')) },
  REWARDS: { path: '/rewards', Element: lazy(() => import('views/Rewards')) },
  SUPPORT: { path: '/support', Element: lazy(() => import('views/Support')) },
  DDC: { path: '/distribution-center', Element: lazy(() => import('views/Account')) },
  SEARCH: { path: '/search', Element: lazy(() => import('views/Search')) }
}

export const Sidebar: SidebarProps = {
  ACCOUNT: {
    id: 'account',
    title: 'Accounts',
    path: `/${basename}${Routes.ACCOUNT.path}`,
    icon: () => <User fontSize={'24px'} />
  },
  DDC: {
    id: 'distribution-center',
    title: 'Distribution Center',
    path: `/${basename}${Routes.DDC.path}`,
    icon: () => <MapPin fontSize={'24px'} />
  },
  PRODUCTS: {
    id: 'product',
    title: 'Products',
    path: `/${basename}${Routes.PRODUCTS.path}`,
    icon: () => <Package fontSize={'24px'} />
  },
  PROMOTIONS: {
    id: 'promotions',
    title: 'Promotions',
    path: `/${basename}${Routes.PROMOTIONS.path}`,
    icon: () => <Tag fontSize={'24px'} />
  },
  ORDERS: {
    id: 'orders',
    title: 'Orders',
    path: `/${basename}${Routes.ORDERS.path}`,
    icon: () => <Currency fontSize={'24px'} />
  },
  EMPTIES: {
    id: 'empties',
    title: 'Empties',
    path: `/${basename}${Routes.EMPTIES.path}`,
    icon: () => <RecycleBottle fontSize={'24px'} />
  },
  INVOICE: {
    id: 'invoice',
    title: 'Invoices',
    path: `/${basename}${Routes.INVOICE.path}`,
    icon: () => <DocumentSigned fontSize={'24px'} />
  },
  RECOMMENDATIONS: {
    id: 'recommendations',
    title: 'Recommendations',
    path: `/${basename}${Routes.RECOMMENDATIONS.path}`,
    icon: () => <Megaphone fontSize={'24px'} />
  },
  REWARDS: {
    id: 'rewards',
    title: 'Rewards',
    path: `/${basename}${Routes.REWARDS.path}`,
    icon: () => <Gift fontSize={'24px'} />
  },
  SEARCH: {
    id: 'search',
    title: 'Data Search',
    path: `/${basename}${Routes.SEARCH.path}`,
    icon: () => <Search fontSize={'24px'} />
  },
  SUPPORT: {
    id: 'support',
    title: 'Support and FAQ',
    path: `/${basename}${Routes.SUPPORT.path}`,
    icon: () => <HelpCircle fontSize={'24px'} />
  }
}
