import { IRepositoryRequirements } from '../../repositories.common'
import { CreateChallengeProps, CreatePointOffersProps } from './Rewards.props'
import { Fetch } from 'utils'

export function RewardsRepository ({ authentication, baseUrl }: IRepositoryRequirements) {
  const url = `${baseUrl}/rewards`

  function enroll (vendorAccountId: string) {
    return Fetch.post({ url: `${url}/enrollments`, data: { vendorAccountId }, config: { headers: authentication } })
  }

  function disenroll (vendorAccountId: string) {
    return Fetch.delete({ url: `${url}/enrollments/${vendorAccountId}`, config: { headers: authentication } })
  }

  function createChallenge (data: CreateChallengeProps) {
    return Fetch.post({ url: `${url}/challenges`, data, config: { headers: authentication } })
  }

  function createPointOffers (data: CreatePointOffersProps) {
    return Fetch.post({ url: `${url}/point-offers`, data, config: { headers: authentication } })
  }

  return {
    createChallenge,
    enroll,
    disenroll,
    createPointOffers
  }
}
